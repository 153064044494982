import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from './apiClient';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { useDropzone } from 'react-dropzone';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { v4 as uuidv4 } from 'uuid';

const mdParser = new MarkdownIt();

const SeccionesTable = () => {
  const { paisId, tipoId, temaId } = useParams();

  const [secciones, setSecciones] = useState([]);
  const [nuevaSeccion, setNuevaSeccion] = useState({ nombre: '', markdown: '', numero: 0, rutaAudio: '' });
  // Removed unused state variable seccionEnEdicion
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (file) => {
    const storage = getStorage();
    // Use the existing section id if editing; if adding, generate a temporary id
    // const sectionId = seccionEditando ? seccionEditando.id : uuidv4();
    // Use tipoId as id_del_permiso and temaId as id_del_tema
    const filePath = `audios/${tipoId}/${temaId}/${file.name}`;
    const storageRef = ref(storage, filePath);
    try {
      await uploadBytes(storageRef, file);
      setNuevaSeccion(prev => ({ ...prev, rutaAudio: filePath }));
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Error al subir el archivo.');
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        handleFileUpload(acceptedFiles[0]);
      }
    },
    multiple: false,
  });

  const baseApiUrl = `${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet/${tipoId}/temario/${temaId}/secciones`;

  const [mostrarModal, setMostrarModal] = useState(false);
  const [permisosDisponibles, setPermisosDisponibles] = useState([]); // Lista de permisos
  const [temasDisponibles, setTemasDisponibles] = useState([]); // Lista de temas según el permiso seleccionado
  const [permisoSeleccionado, setPermisoSeleccionado] = useState('');
  const [temaSeleccionado, setTemaSeleccionado] = useState('');
  const [seccionAClonar, setSeccionAClonar] = useState(null);
  const [nombreTema, setNombreTema] = useState('');

  const [mostrarModalEdicion, setMostrarModalEdicion] = useState(false); // Controla la visibilidad del modal
  const [seccionEditando, setSeccionEditando] = useState(null); // Sección que se está editando en el modal

  // 1️⃣ OBTENER SECCIONES
  const fetchSecciones = async () => {
    setLoading(true);
    try {
      const response = await apiClient(`${baseApiUrl}`);
      setSecciones(response.data);
    } catch (error) {
      console.error('Error al cargar secciones:', error);
      setError('Error al cargar secciones.');
    } finally {
      setLoading(false);
    }
  };
  // 2️⃣ AÑADIR o EDITAR SECCIÓN
  const handleSaveSeccion = async () => {
    if (!nuevaSeccion.nombre || !nuevaSeccion.numero) {
      setError('El nombre y el número son obligatorios.');
      return;
    }

    setError('');
    setSuccess('');

    try {
      if (seccionEditando) {
        await apiClient(`${baseApiUrl}/${seccionEditando.id}`, {
          method: 'PUT',
          data: nuevaSeccion,
        });

        setSecciones(
          secciones.map((sec) =>
            sec.id === seccionEditando.id ? { ...sec, ...nuevaSeccion } : sec
          )
        );

        setSuccess('Sección editada correctamente.');
      } else {
        // Añadir nueva sección
        const response = await apiClient(baseApiUrl, {
          method: 'POST',
          data: nuevaSeccion,
        });

        setSecciones([...secciones, response.data]);
        setSuccess('Sección añadida correctamente.');
      }

      // Cerrar modal y limpiar estados
      setMostrarModalEdicion(false);
      setSeccionEditando(null);
      setNuevaSeccion({ nombre: '', markdown: '', numero: 0, rutaAudio: '' });

    } catch (error) {
      console.error('Error al guardar sección:', error);
      setError('No se pudo guardar la sección.');
    }
  };
  // 3️⃣ ELIMINAR SECCIÓN
  const handleDeleteSeccion = async (sectionId) => {
    setError('');
    setSuccess('');
    try {
      await apiClient(`${baseApiUrl}/${sectionId}`, { method: 'DELETE' });
      setSecciones(secciones.filter((sec) => sec.id !== sectionId));
      setSuccess('Sección eliminada correctamente.');
    } catch (error) {
      console.error('Error al eliminar sección:', error);
      setError('No se pudo eliminar la sección.');
    }
  };
  // 4️⃣ CARGAR SECCIÓN EN EL FORMULARIO AL EDITAR
  const handleEditSeccion = (seccion) => {
    setSeccionEditando(seccion);
    setNuevaSeccion({ nombre: seccion.nombre, markdown: seccion.markdown, numero: seccion.numero, rutaAudio: seccion.rutaAudio || '' });
    setMostrarModalEdicion(true);
  };
  const handleOpenClonarModal = async (seccion) => {
    setSeccionAClonar(seccion);
    setMostrarModal(true);

    try {
      const response = await apiClient(`${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet`);
      setPermisosDisponibles(response.data);
    } catch (error) {
      console.error('Error al cargar permisos:', error);
    }
  };
  const handleChangePermiso = async (permisoId) => {
    setPermisoSeleccionado(permisoId);
    setTemaSeleccionado(''); // Reiniciar tema seleccionado

    try {
      const response = await apiClient(`${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet/${permisoId}/temario`);
      setTemasDisponibles(response.data);
    } catch (error) {
      console.error('Error al cargar temas:', error);
    }
  };
  const handleClonarSeccion = async () => {
    if (!permisoSeleccionado || !temaSeleccionado) {
      setError('Debes seleccionar un permiso y un tema.');
      return;
    }

    try {
      const nuevaSeccion = { ...seccionAClonar };
      delete nuevaSeccion.id; // Eliminamos el ID para que Firestore genere uno nuevo

      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet/${permisoSeleccionado}/temario/${temaSeleccionado}/secciones`;

      await apiClient(apiUrl, {
        method: 'POST',
        data: nuevaSeccion,
      });

      setSuccess('Sección clonada correctamente.');
      setMostrarModal(false);
    } catch (error) {
      console.error('Error al clonar sección:', error);
      setError('No se pudo clonar la sección.');
    }
  };
  const fetchNombreTema = async () => {
    try {
      const response = await apiClient(`${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet/${tipoId}/temario/${temaId}`);
      setNombreTema(response.data.nombre);
    } catch (error) {
      console.error('Error al cargar el nombre del tema:', error);
    }
  };

  useEffect(() => {
    fetchSecciones();
    fetchNombreTema();
  }, [paisId, tipoId, temaId]);

  return (
    <div className="p-4 border rounded-md shadow-md mt-4">
      <h3 className="text-xl font-semibold mb-2">{`Secciones del tema: ${nombreTema}` || 'Cargando...'}</h3>

      {error && <p className="text-red-500 mb-2">{error}</p>}
      {success && <p className="text-green-500 mb-2">{success}</p>}

      {/* 🔹 FORMULARIO PARA AÑADIR O EDITAR SECCIÓN */}
      <div className="mb-4 p-4 border rounded-md shadow-md">
        <h3 className="text-lg font-semibold">
          {seccionEditando ? 'Editar Sección' : 'Añadir Nueva Sección'}
        </h3>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Nombre de la Sección:</label>
          <input
            type="text"
            value={nuevaSeccion.nombre}
            onChange={(e) => setNuevaSeccion({ ...nuevaSeccion, nombre: e.target.value })}
            className="w-full border border-gray-300 rounded-md p-2 mt-1"
          />
        </div>
        {/* 🔹 EDITOR DE MARKDOWN SOLO AL EDITAR */}
        {seccionEditando && (
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Contenido (Markdown):</label>
            <MdEditor
              value={nuevaSeccion.markdown}
              renderHTML={(text) => mdParser.render(text)}
              onChange={({ text }) => setNuevaSeccion({ ...nuevaSeccion, markdown: text })}
              className="border border-gray-300 rounded-md"
              style={{ height: "300px" }}
            />
          </div>
        )}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Número:</label>
          <input
            type="text"
            value={nuevaSeccion.numero}
            onChange={(e) => setNuevaSeccion({ ...nuevaSeccion, numero: Number(e.target.value) })}
            className="w-full border border-gray-300 rounded-md p-2 mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Ruta del Audio:</label>
          <input
            type="text"
            value={nuevaSeccion.rutaAudio}
            onChange={(e) => setNuevaSeccion({ ...nuevaSeccion, rutaAudio: e.target.value })}
            className="w-full border border-gray-300 rounded-md p-2 mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Subir Archivo de Audio (Dropzone):</label>
          <div {...getRootProps()} className="w-full border-2 border-dashed border-gray-300 rounded-md p-4 mt-1 text-center cursor-pointer">
            <input {...getInputProps()} />
            {
              isDragActive ? 
                <p>Suelta el archivo aquí...</p> : 
                <p>Arrastra y suelta tu archivo de audio aquí, o haz clic para seleccionar</p>
            }
          </div>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={handleSaveSeccion}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            {seccionEditando ? 'Guardar Cambios' : 'Añadir Sección'}
          </button>
          {seccionEditando && (
            <button
              onClick={() => {
                setSeccionEditando(null);
                setNuevaSeccion({ nombre: '', markdown: '', numero: 0, rutaAudio: '' });
              }}
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Cancelar
            </button>
          )}
        </div>
      </div>

      {/* 🔹 LISTADO DE SECCIONES */}
      {loading ? (
        <p className="text-center text-gray-500 mt-4">Cargando secciones...</p>
      ) : (
        <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-md mt-4">
          <thead>
            <tr className="bg-gray-100 border-b">
              <th className="py-2 px-4 text-left font-semibold text-gray-700">Nombre</th>
              <th className="py-2 px-4 text-center font-semibold text-gray-700">Número</th>
              <th className="py-2 px-4 text-center font-semibold text-gray-700">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {secciones.map((sec) => (
              <tr key={sec.id} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">{sec.nombre}</td>
                <td className="py-2 px-4">{sec.numero}</td>
                <td className="py-2 px-4 text-center">
                  <button
                    onClick={() => handleEditSeccion(sec)}
                    className="bg-yellow-500 text-white px-3 py-1 rounded-md hover:bg-yellow-600 mr-2"
                  >
                    Editar
                  </button>
                  <button
                    onClick={() => handleOpenClonarModal(sec)}
                    className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 mr-2"
                  >
                    Clonar
                  </button>
                  <button onClick={() => handleDeleteSeccion(sec.id)} className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600">
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {mostrarModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md w-96">
            <h3 className="text-lg font-semibold mb-2">Clonar Sección</h3>

            {/* Selector de Permiso */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Seleccionar Permiso:</label>
              <select
                value={permisoSeleccionado}
                onChange={(e) => handleChangePermiso(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mt-1"
              >
                <option value="">-- Selecciona un permiso --</option>
                {permisosDisponibles.map((permiso) => (
                  <option key={permiso.id} value={permiso.id}>{permiso.nombre}</option>
                ))}
              </select>
            </div>

            {/* Selector de Tema */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Seleccionar Tema:</label>
              <select
                value={temaSeleccionado}
                onChange={(e) => setTemaSeleccionado(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mt-1"
                disabled={!permisoSeleccionado}
              >
                <option value="">-- Selecciona un tema --</option>
                {temasDisponibles.map((tema) => (
                  <option key={tema.id} value={tema.id}>{tema.nombre}</option>
                ))}
              </select>
            </div>

            {/* Botones */}
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setMostrarModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
              >
                Cancelar
              </button>
              <button
                onClick={handleClonarSeccion}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                disabled={!permisoSeleccionado || !temaSeleccionado}
              >
                Confirmar Clonación
              </button>
            </div>
          </div>
        </div>
      )}

      {mostrarModalEdicion && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[1000px] max-w-5xl h-[600px] max-h-screen overflow-y-auto">
            <h3 className="text-xl font-semibold mb-4">Editar Sección</h3>

            {error && <p className="text-red-500 mb-2">{error}</p>}
            {success && <p className="text-green-500 mb-2">{success}</p>}

            {/* Nombre de la Sección */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Nombre de la Sección:</label>
              <input
                type="text"
                value={nuevaSeccion.nombre}
                onChange={(e) => setNuevaSeccion({ ...nuevaSeccion, nombre: e.target.value })}
                className="w-full border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Ruta del Audio:</label>
              <input
                type="text"
                value={nuevaSeccion.rutaAudio}
                onChange={(e) => setNuevaSeccion({ ...nuevaSeccion, rutaAudio: e.target.value })}
                className="w-full border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Subir Archivo de Audio (Dropzone):</label>
              <div {...getRootProps()} className="w-full border-2 border-dashed border-gray-300 rounded-md p-4 mt-1 text-center cursor-pointer">
                <input {...getInputProps()} />
                {
                  isDragActive ? 
                    <p>Suelta el archivo aquí...</p> : 
                    <p>Arrastra y suelta tu archivo de audio aquí, o haz clic para seleccionar</p>
                }
              </div>
            </div>

            {/* Editor Markdown */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Contenido (Markdown):</label>
              <MdEditor
                value={nuevaSeccion.markdown}
                renderHTML={(text) => mdParser.render(text)}
                onChange={({ text }) => setNuevaSeccion({ ...nuevaSeccion, markdown: text })}
                className="border border-gray-300 rounded-md"
                style={{ height: "250px" }} // 🔹 Aumentar altura del editor
              />
            </div>

            {/* Número de Sección */}
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Número:</label>
              <input
                type="number"
                value={nuevaSeccion.numero}
                onChange={(e) => setNuevaSeccion({ ...nuevaSeccion, numero: Number(e.target.value) })}
                className="w-full border border-gray-300 rounded-md p-2 mt-1"
              />
            </div>

            {/* Botones */}
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setMostrarModalEdicion(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
              >
                Cancelar
              </button>
              <button
                onClick={handleSaveSeccion}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Guardar Cambios
              </button>
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default SeccionesTable;