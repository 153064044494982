import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import apiClient from './apiClient';

const Temario = () => {
  // 1) Leer los parámetros de la ruta
  const { paisId, tipoId } = useParams();

  // 2) Estados
  const [temas, setTemas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Para crear/editar un tema
  const [temaEnEdicion, setTemaEnEdicion] = useState(null);
  const [nuevoTema, setNuevoTema] = useState({
    nombre: '',
    descripcion: '',
    numero: '',
    icono: '',
    visible: false,
  });

  // Endpoints (ajusta con tus propias rutas en el backend)
  const baseApiUrl = `${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet/${tipoId}/temario`;

  // 3) Cargar los temas cuando se montan (o cuando cambian paisId/tipoId)
  useEffect(() => {
    fetchTemas();
    // eslint-disable-next-line
  }, [paisId, tipoId]);

  const fetchTemas = async () => {
    setLoading(true);
    try {
      // Supongamos que tu backend espera la ruta:
      // GET /api/paises/:paisId/tipos/:tipoId/temas
      // y en React la construyes así:
      const response = await apiClient(`${baseApiUrl}`);
      // Ordenar los temas por 'numero'
      const temasOrdenados = response.data.sort((a, b) => a.numero - b.numero);
      setTemas(temasOrdenados);
    } catch (err) {
      console.error('Error al cargar los temas:', err);
      setError('No se pudieron cargar los temas.');
    } finally {
      setLoading(false);
    }
  };

  // 4) Guardar un tema (crear o editar)
  const handleSaveTema = async () => {
    // Validamos campos
    if (!nuevoTema.nombre || !nuevoTema.descripcion || !nuevoTema.numero || !nuevoTema.icono) {
      setError('Todos los campos son obligatorios.');
      return;
    }
    setError('');
    setSuccess('');

    if (temaEnEdicion) {
      // EDITAR TEMA (PUT /api/paises/:paisId/tipos/:tipoId/temas/:temaId)
      try {
        const temaId = temaEnEdicion.id;
        await apiClient(`${baseApiUrl}/${temaId}`, {
          method: 'PUT',
          data: nuevoTema,
        });
        // Actualizamos en el estado local
        setTemas(
          temas.map((t) => (t.id === temaId ? { ...nuevoTema, id: temaId } : t))
        );
        setSuccess('Tema editado correctamente.');
        // Limpiar
        setTemaEnEdicion(null);
        setNuevoTema({ nombre: '', descripcion: '', numero: '', icono: '', visible: false });
      } catch (err) {
        console.error('Error al editar el tema:', err);
        setError('Error al editar el tema.');
      }
    } else {
      // CREAR TEMA (POST /api/paises/:paisId/tipos/:tipoId/temas)
      try {
        const response = await apiClient(`${baseApiUrl}`, {
          method: 'POST',
          data: nuevoTema,
        });
        // Supongamos que el backend retorna el objeto creado con su ID
        const temaCreado = response.data;
        setTemas([...temas, temaCreado]);
        setSuccess('Tema añadido correctamente.');
        // Limpiar
        setNuevoTema({ nombre: '', descripcion: '', numero: '', icono: '', visible: false });
      } catch (err) {
        console.error('Error al añadir el tema:', err);
        setError('Error al añadir el tema.');
      }
    }
  };

  // 5) Eliminar un tema
  const handleDeleteTema = async (temaId) => {
    setError('');
    setSuccess('');
    try {
      // DELETE /api/paises/:paisId/tipos/:tipoId/temas/:temaId
      await apiClient(`${baseApiUrl}/${temaId}`, { method: 'DELETE' });
      // Actualizar estado local
      setTemas(temas.filter((t) => t.id !== temaId));
      setSuccess('Tema eliminado correctamente.');
    } catch (err) {
      console.error('Error al eliminar el tema:', err);
      setError('Error al eliminar el tema.');
    }
  };

  // 6) Preparar edición
  const handleEditTema = (tema) => {
    setTemaEnEdicion(tema);
    setNuevoTema({
      nombre: tema.nombre,
      descripcion: tema.descripcion,
      numero: tema.numero,
      icono: tema.icono,
      visible: tema.visible || false,
    });
  };

  // 7) Cancelar edición
  const handleCancelEdit = () => {
    setTemaEnEdicion(null);
    setNuevoTema({ nombre: '', descripcion: '', numero: '', icono: '', visible: false });
    setError('');
    setSuccess('');
  };

  return (
    <div className="p-6 w-full">
      <h2 className="text-2xl font-semibold text-center mb-4">
        Temario - País: {paisId}, Tipo: {tipoId}
      </h2>

      {error && <p className="text-red-500 mb-2">{error}</p>}
      {success && <p className="text-green-500 mb-2">{success}</p>}

      {/* Formulario para crear o editar un tema */}
      <div className="p-4 border rounded-md shadow-md mb-6">
        <h3 className="text-xl font-semibold mb-2">
          {temaEnEdicion ? 'Editar Tema' : 'Añadir un Nuevo Tema'}
        </h3>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Número del Tema:</label>
          <input
            type="number"
            value={nuevoTema.numero}
            onChange={(e) =>
              setNuevoTema({ ...nuevoTema, numero: parseInt(e.target.value, 10) })
            }
            className="w-full border border-gray-300 rounded-md p-2 mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Nombre:</label>
          <input
            type="text"
            value={nuevoTema.nombre}
            onChange={(e) => setNuevoTema({ ...nuevoTema, nombre: e.target.value })}
            className="w-full border border-gray-300 rounded-md p-2 mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Descripción:</label>
          <textarea
            value={nuevoTema.descripcion}
            onChange={(e) => setNuevoTema({ ...nuevoTema, descripcion: e.target.value })}
            className="w-full border border-gray-300 rounded-md p-2 mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Icono:</label>
          <input
            type="text"
            value={nuevoTema.icono}
            onChange={(e) => setNuevoTema({ ...nuevoTema, icono: e.target.value })}
            className="w-full border border-gray-300 rounded-md p-2 mt-1"
          />
        </div>
        <div className="mb-4 flex items-center">
          <input
            id="visible"
            type="checkbox"
            checked={nuevoTema.visible}
            onChange={(e) =>
              setNuevoTema({ ...nuevoTema, visible: e.target.checked })
            }
            className="mr-2"
          />
          <label htmlFor="visible" className="text-gray-700 font-medium">
            Visible
          </label>
        </div>
        <button
          onClick={handleSaveTema}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          {temaEnEdicion ? 'Guardar Cambios' : 'Añadir Tema'}
        </button>
        {temaEnEdicion && (
          <button
            onClick={handleCancelEdit}
            className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
          >
            Cancelar
          </button>
        )}
      </div>

      {/* Tabla de temas */}
      <div className="overflow-x-auto">
        {loading ? (
          <p className="text-center text-gray-500">Cargando temas...</p>
        ) : temas.length === 0 ? (
          <p className="text-center text-gray-500">No se encontraron temas.</p>
        ) : (
          <table className="w-full bg-white border border-gray-200 shadow-md rounded-md">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Número</th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Nombre</th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Descripción</th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Icono</th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">Visible</th>
                <th className="py-2 px-4 text-center font-semibold text-gray-700">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {temas.map((tema) => (
                <tr key={tema.id} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4">{tema.numero}</td>
                  <td className="py-2 px-4">{tema.nombre}</td>
                  <td className="py-2 px-4">{tema.descripcion}</td>
                  <td className="py-2 px-4">{tema.icono}</td>
                  <td className="py-2 px-4">{tema.visible ? 'Sí' : 'No'}</td>
                  <td className="py-2 px-4 text-center">
                    {/* Editar */}
                    <button
                      onClick={() => handleEditTema(tema)}
                      className="bg-yellow-500 text-white px-3 py-1 rounded-md hover:bg-yellow-600 mr-2"
                    >
                      Editar
                    </button>
                    {/* Eliminar */}
                    <button
                      onClick={() => handleDeleteTema(tema.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 mr-2"
                    >
                      Eliminar
                    </button>

                    {/* Ir a Preguntas */}
                    <Link
                      to={`/paises/${paisId}/tipos-de-permisos/${tipoId}/temario/${tema.id}/preguntas`}
                      className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600 mr-2"
                    >
                      Preguntas
                    </Link>

                    {/* Ir a Secciones */}
                    <Link
                      to={`/paises/${paisId}/tipos-de-permisos/${tipoId}/temario/${tema.id}/secciones`}
                      className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600"
                    >
                      Secciones
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Temario;