import React, { useEffect, useState } from "react";
import apiClient from "./apiClient";
import moment from "moment";

const PreguntasReportadas = () => {
    const [preguntas, setPreguntas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

    // Obtener preguntas reportadas
    const fetchPreguntasReportadas = async () => {
        setLoading(true);
        try {
            const response = await apiClient(`${API_BASE_URL}/preguntas-reportadas`);
            setPreguntas(
                response.data.sort((a, b) => a.question.questionText.localeCompare(b.question.questionText))
            );
        } catch (error) {
            console.error("Error al obtener preguntas reportadas:", error);
            setError("No se pudieron cargar las preguntas reportadas.");
        } finally {
            setLoading(false);
        }
    };

    // Marcar pregunta como corregida
    const marcarComoCorregida = async (id) => {
        try {
            await apiClient(`${API_BASE_URL}/preguntas-reportadas/${id}`, {
                method: "PATCH",
                data: { corregida: true }
            });

            // Actualizar estado localmente sin recargar
            setPreguntas((prevPreguntas) =>
                prevPreguntas.map((pregunta) =>
                    pregunta.id === id ? { ...pregunta, corregida: true } : pregunta
                )
            );
        } catch (error) {
            console.error("Error al marcar pregunta como corregida:", error);
            alert("No se pudo marcar como corregida.");
        }
    };

    // Cargar preguntas al montar el componente
    useEffect(() => {
        fetchPreguntasReportadas();
    }, []);

    return (
        <div className="p-6 w-full">
            <h2 className="text-2xl font-semibold text-center mb-4">Preguntas Reportadas</h2>
            {error && <p className="text-red-500 mb-2">{error}</p>}

            <div className="overflow-x-auto">
                {loading ? (
                    <p className="text-center text-gray-500">Cargando preguntas reportadas...</p>
                ) : preguntas.length === 0 ? (
                    <p className="text-center text-gray-500">No hay preguntas reportadas.</p>
                ) : (
                    <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
                        <thead>
                            <tr className="bg-gray-100 border-b">
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Pregunta</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Respuestas</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Imagen</th>

                                {/* <th className="py-2 px-4 text-left font-semibold text-gray-700">Reportado por</th> */}
                                <th className="py-2 px-4 text-center font-semibold text-gray-700">Fecha</th>
                                <th className="py-2 px-4 text-center font-semibold text-gray-700">Reportado por</th>
                                <th className="py-2 px-4 text-center font-semibold text-gray-700">Corregido</th>
                                <th className="py-2 px-4 text-center font-semibold text-gray-700">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {preguntas.filter(pregunta => !pregunta.corregida).map((pregunta) => (
                                <tr key={pregunta.id} className="border-b hover:bg-gray-50">
                                    <td className="py-2 px-4">{pregunta.question.questionText}</td>
                                    <td className="py-2 px-4">
                                        {pregunta.question.answers &&
                                            pregunta.question.answers.map((ans, i) => (
                                                <div key={i}>
                                                    {ans.answerText} {ans.correct && <strong>(Correcta)</strong>}
                                                </div>
                                            ))}
                                    </td>
                                    <td className="py-2 px-4">
                                        <img width="100px" src={pregunta.question.imageUrl}></img>
                                        {pregunta.question.imageUrl}
                                    </td>

                                    {/* <td className="py-2 px-4">{pregunta.reportedBy}</td> */}
                                    <td className="py-2 px-4 text-center">
                                        {pregunta.reportedAt && pregunta.reportedAt._seconds
                                            ? moment.unix(pregunta.reportedAt._seconds).format("DD/MM/YYYY HH:mm")
                                            : "Fecha desconocida"}
                                    </td>
                                    <td className="py-2 px-4 text-center">
                                        {pregunta.reportedBy}
                                    </td>
                                    <td className="py-2 px-4 text-center">
                                        {pregunta.corregida ? "Sí" : "No"}
                                    </td>
                                    <td className="py-2 px-4 text-center">
                                        {!pregunta.corregida && (
                                            <button
                                                onClick={() => marcarComoCorregida(pregunta.id)}
                                                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                                            >
                                                CORREGIDA
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default PreguntasReportadas;