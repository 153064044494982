import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from './apiClient';

const Preguntas = () => {
    const [preguntas, setPreguntas] = useState([]);
    const [preguntaEnEdicion, setPreguntaEnEdicion] = useState(null);
    const [preguntaFormulario, setPreguntaFormulario] = useState({
        questionText: '',
        answers: [
            { answerText: '', correct: false },
            { answerText: '', correct: false },
            { answerText: '', correct: false },
        ],
        imageUrl: '',
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [jsonPreguntas, setJsonPreguntas] = useState([]);

    const { paisId, tipoId, temaId } = useParams();
    const baseApiUrl = `${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet/${tipoId}/temario/${temaId}/preguntas`;
    const [totalPreguntas, setTotalPreguntas] = useState(0);

    const [mostrarModalCopiar, setMostrarModalCopiar] = useState(false);
    const [permisosDisponibles, setPermisosDisponibles] = useState([]);
    const [temasDisponibles, setTemasDisponibles] = useState([]);
    const [permisoSeleccionado, setPermisoSeleccionado] = useState('');
    const [temaSeleccionado, setTemaSeleccionado] = useState('');


    useEffect(() => {
        const fetchPreguntas = async () => {
            setLoading(true);
            try {
                const response = await apiClient(`${baseApiUrl}?limit=10000`);
                setPreguntas(response.data.questions);
                setTotalPreguntas(response.data.total);
            } catch (error) {
                console.error('Error al cargar preguntas:', error);
                setError('No se pudieron cargar las preguntas.');
            } finally {
                setLoading(false);
            }
        };
        fetchPreguntas();
    }, [paisId, tipoId, temaId]);

    // Manejo de la carga del JSON
    const handleFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer?.files[0];
        if (!file || file.type !== 'application/json') {
            alert('Por favor, arrastra un archivo JSON válido.');
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const parsedQuestions = JSON.parse(e.target.result);
                if (!Array.isArray(parsedQuestions)) {
                    throw new Error('El archivo JSON debe contener un array de preguntas.');
                }
                setJsonPreguntas(parsedQuestions);
            } catch (error) {
                alert('Error al leer el archivo JSON: ' + error.message);
            }
        };
        reader.readAsText(file);
    };

    const preventDefaults = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // Subir preguntas del JSON al backend
    const uploadJsonQuestions = async () => {
        if (jsonPreguntas.length === 0) {
            alert('No hay preguntas para importar.');
            return;
        }

        setLoading(true);
        try {
            const promises = jsonPreguntas.map((question) => {
                const payload = {
                    ...question,
                    country: paisId,
                    permission: tipoId,
                    temary: temaId,
                };

                return apiClient(`${baseApiUrl}`, {
                    method: 'POST',
                    data: payload,
                });
            });

            await Promise.all(promises);
            alert('Preguntas importadas con éxito.');
            setModalOpen(false);
            setJsonPreguntas([]);
            // Actualizamos la lista de preguntas luego de importar
            const response = await apiClient(`${baseApiUrl}`);
            setPreguntas(response.data.questions);
        } catch (error) {
            console.error('Error al importar preguntas:', error);
            alert('Ocurrió un error al importar las preguntas.');
        } finally {
            setLoading(false);
        }
    };

    // Agregar una nueva pregunta
    const addQuestion = async () => {
        if (!preguntaFormulario.questionText.trim()) {
            alert('La pregunta no puede estar vacía.');
            return;
        }

        setLoading(true);
        try {
            const response = await apiClient(`${baseApiUrl}`, {
                method: 'POST',
                data: {
                    ...preguntaFormulario,
                    country: paisId,
                    permission: tipoId,
                    temary: temaId,
                },
            });

            setPreguntas([...preguntas, response.data]);
            setPreguntaFormulario({
                questionText: '',
                answers: [
                    { answerText: '', correct: false },
                    { answerText: '', correct: false },
                    { answerText: '', correct: false },
                ],
                imageUrl: '',
            });

            alert('Pregunta añadida con éxito.');
        } catch (error) {
            console.error('Error al añadir la pregunta:', error);
            alert('No se pudo añadir la pregunta.');
        } finally {
            setLoading(false);
        }
    };

    // Cargar la pregunta en el formulario para editar
    const handleEditClick = (question) => {
        setPreguntaEnEdicion(question);
        setPreguntaFormulario({
            questionText: question.questionText,
            answers: question.answers,
            imageUrl: question.imageUrl || '',
        });
    };

    // Actualizar la pregunta existente
    const updateQuestion = async () => {
        if (!preguntaFormulario.questionText.trim()) {
            alert('La pregunta no puede estar vacía.');
            return;
        }

        if (!preguntaEnEdicion || !preguntaEnEdicion.id) {
            alert('No se puede editar esta pregunta.');
            return;
        }

        setLoading(true);
        try {
            const response = await apiClient(`${baseApiUrl}/${preguntaEnEdicion.id}`, {
                method: 'PUT',
                data: {
                    ...preguntaFormulario,
                    country: paisId,
                    permission: tipoId,
                    temary: temaId,
                },
            });

            // Actualizamos la lista local de preguntas
            const updatedQuestions = preguntas.map((q) =>
                q.id === preguntaEnEdicion.id ? response.data : q
            );
            setPreguntas(updatedQuestions);
            setPreguntaEnEdicion(null);
            setPreguntaFormulario({
                questionText: '',
                answers: [
                    { answerText: '', correct: false },
                    { answerText: '', correct: false },
                    { answerText: '', correct: false },
                ],
                imageUrl: '',
            });
            alert('Pregunta actualizada con éxito.');
        } catch (error) {
            console.error('Error al actualizar la pregunta:', error);
            alert('No se pudo actualizar la pregunta.');
        } finally {
            setLoading(false);
        }
    };

    const deleteQuestion = async (questionId) => {
        if (!window.confirm('¿Estás seguro de que deseas eliminar esta pregunta?')) {
            return;
        }

        setLoading(true);
        try {
            await apiClient(`${baseApiUrl}/${questionId}`, {
                method: 'DELETE',
            });

            // Filtramos la pregunta eliminada de la lista de preguntas
            setPreguntas(preguntas.filter((q) => q.id !== questionId));
            setTotalPreguntas((prevTotal) => prevTotal - 1);
            alert('Pregunta eliminada con éxito.');
        } catch (error) {
            console.error('Error al eliminar la pregunta:', error);
            alert('No se pudo eliminar la pregunta.');
        } finally {
            setLoading(false);
        }
    };

    const handleOpenCopiarModal = async () => {
        setMostrarModalCopiar(true);

        try {
            const response = await apiClient(`${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet`);
            setPermisosDisponibles(response.data);
        } catch (error) {
            console.error('Error al cargar permisos:', error);
        }
    };
    const handleChangePermiso = async (permisoId) => {
        setPermisoSeleccionado(permisoId);
        setTemaSeleccionado(''); // Reiniciar selección de tema

        try {
            const response = await apiClient(`${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet/${permisoId}/temario`);
            setTemasDisponibles(response.data);
        } catch (error) {
            console.error('Error al cargar temas:', error);
        }
    };
    const handleCopiarPreguntas = async () => {
        if (!permisoSeleccionado || !temaSeleccionado) {
            alert('Debes seleccionar un permiso y un tema.');
            return;
        }

        setLoading(true);

        try {
            const preguntasClonadas = preguntas.map((pregunta) => ({
                ...pregunta,
                country: paisId,
                permission: permisoSeleccionado,
                temary: temaSeleccionado,
            }));

            const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet/${permisoSeleccionado}/temario/${temaSeleccionado}/preguntas`;

            await Promise.all(preguntasClonadas.map((pregunta) =>
                apiClient(apiUrl, {
                    method: 'POST',
                    data: pregunta,
                })
            ));

            alert('Preguntas copiadas con éxito.');
            setMostrarModalCopiar(false);
        } catch (error) {
            console.error('Error al copiar preguntas:', error);
            alert('Ocurrió un error al copiar las preguntas.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-6 w-full">
            <h2 className="text-2xl font-semibold text-center mb-4">
                Preguntas del Temario {temaId ? ` - ID: ${temaId}` : ''}
            </h2>
            {error && <p className="text-red-500 mb-2">{error}</p>}

            {/* Formulario para Añadir o Editar Pregunta */}
            <div className="p-4 border rounded-md shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-2">
                    {preguntaEnEdicion ? 'Editar Pregunta' : 'Añadir Nueva Pregunta'}
                </h3>
                <input
                    type="text"
                    value={preguntaFormulario.questionText}
                    onChange={(e) =>
                        setPreguntaFormulario({ ...preguntaFormulario, questionText: e.target.value })
                    }
                    placeholder="Escribe la pregunta"
                    className="w-full border border-gray-300 rounded-md p-2 mt-1"
                />

                {preguntaFormulario.answers.map((answer, index) => (
                    <div key={index} className="mt-4 flex items-center">
                        <input
                            type="text"
                            value={answer.answerText}
                            onChange={(e) => {
                                const updatedAnswers = [...preguntaFormulario.answers];
                                updatedAnswers[index].answerText = e.target.value;
                                setPreguntaFormulario({ ...preguntaFormulario, answers: updatedAnswers });
                            }}
                            className="w-full border border-gray-300 rounded-md p-2"
                            placeholder={`Respuesta ${index + 1}`}
                        />
                        <input
                            type="checkbox"
                            checked={answer.correct}
                            onChange={() => {
                                const updatedAnswers = preguntaFormulario.answers.map((ans, i) => ({
                                    ...ans,
                                    correct: i === index,
                                }));
                                setPreguntaFormulario({ ...preguntaFormulario, answers: updatedAnswers });
                            }}
                            className="ml-2"
                        />
                    </div>
                ))}
                <input
                    type="text"
                    value={preguntaFormulario.imageUrl}
                    onChange={(e) =>
                        setPreguntaFormulario({ ...preguntaFormulario, imageUrl: e.target.value })
                    }
                    placeholder="URL de la imagen"
                    className="w-full border border-gray-300 rounded-md p-2 mt-4"
                />
                <div className="flex space-x-2 mt-5">
                    {preguntaEnEdicion ? (
                        <>
                            <button
                                onClick={updateQuestion}
                                className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600"
                            >
                                Actualizar Pregunta
                            </button>
                            <button
                                onClick={() => {
                                    setPreguntaEnEdicion(null);
                                    setPreguntaFormulario({
                                        questionText: '',
                                        answers: [
                                            { answerText: '', correct: false },
                                            { answerText: '', correct: false },
                                            { answerText: '', correct: false },
                                        ],
                                        imageUrl: '',
                                    });
                                }}
                                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                            >
                                Cancelar
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={addQuestion}
                                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                            >
                                Añadir Pregunta
                            </button>
                            <button
                                onClick={() => setModalOpen(true)}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                            >
                                Importar JSON
                            </button>

                        </>
                    )}
                </div>
            </div>

            {/* Tabla de Preguntas */}
            <div className="mb-6 overflow-x-auto">
                <h3 className="text-xl font-semibold mb-2">Lista de Preguntas</h3>
                <h5>Total: {totalPreguntas}</h5>

                <button
                    onClick={handleOpenCopiarModal}
                    className="px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600"
                >
                    Copiar Preguntas
                </button>

                {loading ? (
                    <p>Cargando...</p>
                ) : (
                    <table className="w-full bg-white">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">ID</th>
                                <th className="py-2 px-4 border-b">Pregunta</th>
                                <th className="py-2 px-4 border-b">Respuestas</th>
                                <th className="py-2 px-4 border-b">Imagen</th>
                                <th className="py-2 px-4 border-b">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {preguntas.map((question) => (
                                <tr key={question.id} className="text-center">
                                    <td className="py-2 px-4 border-b">{question.id}</td>
                                    <td className="py-2 px-4 border-b">{question.questionText}</td>
                                    <td className="py-2 px-4 border-b">
                                        {question.answers &&
                                            question.answers.map((ans, i) => (
                                                <div key={i}>
                                                    {ans.answerText} {ans.correct && <strong>(Correcta)</strong>}
                                                </div>
                                            ))}
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <img width="100px" src={question.imageUrl}></img>
                                        {question.imageUrl}</td>

                                    <td className="py-2 px-4 border-b">
                                        <button
                                            onClick={() => handleEditClick(question)}
                                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                        >
                                            Editar
                                        </button>
                                        <button
                                            onClick={() => deleteQuestion(question.id)}
                                            className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 ml-2"
                                        >
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>

            {/* MODAL PARA IMPORTAR JSON */}
            {modalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                        <h3 className="text-xl font-semibold text-center mb-4">Importar Preguntas desde JSON</h3>
                        <div
                            onDrop={handleFileDrop}
                            onDragOver={preventDefaults}
                            onDragEnter={preventDefaults}
                            onDragLeave={preventDefaults}
                            className="border-2 border-dashed border-gray-300 rounded-md p-6 text-center hover:border-blue-500 cursor-pointer"
                        >
                            <p className="text-gray-500">Arrastra y suelta un archivo JSON aquí.</p>
                        </div>

                        {jsonPreguntas.length > 0 && (
                            <p className="text-gray-700 text-center mt-4">
                                <strong>{jsonPreguntas.length}</strong> preguntas listas para importar.
                            </p>
                        )}

                        <div className="flex justify-between mt-4">
                            <button
                                onClick={uploadJsonQuestions}
                                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                            >
                                Importar
                            </button>
                            <button
                                onClick={() => setModalOpen(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {mostrarModalCopiar && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-6 rounded-md shadow-md w-[800px] max-w-3xl">
                        <h3 className="text-lg font-semibold mb-2">Copiar Preguntas</h3>

                        {/* Selector de Permiso */}
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium">Seleccionar Permiso:</label>
                            <select
                                value={permisoSeleccionado}
                                onChange={(e) => handleChangePermiso(e.target.value)}
                                className="w-full border border-gray-300 rounded-md p-2 mt-1"
                            >
                                <option value="">-- Selecciona un permiso --</option>
                                {permisosDisponibles.map((permiso) => (
                                    <option key={permiso.id} value={permiso.id}>{permiso.nombre}</option>
                                ))}
                            </select>
                        </div>

                        {/* Selector de Tema */}
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium">Seleccionar Tema:</label>
                            <select
                                value={temaSeleccionado}
                                onChange={(e) => setTemaSeleccionado(e.target.value)}
                                className="w-full border border-gray-300 rounded-md p-2 mt-1"
                                disabled={!permisoSeleccionado}
                            >
                                <option value="">-- Selecciona un tema --</option>
                                {temasDisponibles.map((tema) => (
                                    <option key={tema.id} value={tema.id}>{tema.nombre}</option>
                                ))}
                            </select>
                        </div>

                        {/* Botones */}
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setMostrarModalCopiar(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleCopiarPreguntas}
                                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                                disabled={!permisoSeleccionado || !temaSeleccionado}
                            >
                                Confirmar Copia
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Preguntas;