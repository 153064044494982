import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import apiClient from './apiClient';

const TiposDeCarnet = () => {
    const [tipos, setTipos] = useState([]);
    const [tipoEnEdicion, setTipoEnEdicion] = useState(null);
    const [tipoFormulario, setTipoFormulario] = useState({ nombre: '', descripcion: '', visible: false });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { paisId } = useParams();
    const baseApiUrl = `${process.env.REACT_APP_API_BASE_URL}/paises/${paisId}/tiposDeCarnet`;

    // 1️⃣ Cargar los tipos de carnet
    useEffect(() => {
        const fetchTipos = async () => {
            setLoading(true);
            try {
                const response = await apiClient(`${baseApiUrl}`);
                setTipos(response.data);
            } catch (error) {
                console.error('Error al cargar tipos de carnet:', error);
                setError('No se pudieron cargar los tipos de carnet.');
            } finally {
                setLoading(false);
            }
        };
        fetchTipos();
    }, [paisId]);

    // 2️⃣ Añadir o Editar un tipo de carnet
    const handleSubmit = async () => {
        if (!tipoFormulario.nombre || !tipoFormulario.descripcion) {
            setError('Todos los campos son obligatorios.');
            return;
        }
        setError('');

        try {
            if (tipoEnEdicion) {
                // Modo edición (actualizar tipo existente)
                await apiClient(`${baseApiUrl}/${tipoEnEdicion}`, {
                    method: 'PUT',
                    data: tipoFormulario,
                });

                // Actualizar la lista local
                setTipos(tipos.map((t) => (t.id === tipoEnEdicion ? { id: tipoEnEdicion, ...tipoFormulario } : t)));
            } else {
                // Modo añadir (crear nuevo tipo)
                const response = await apiClient(`${baseApiUrl}`, {
                    method: 'POST',
                    data: tipoFormulario,
                });

                setTipos([...tipos, response.data]);
            }

            // Resetear el formulario
            setTipoEnEdicion(null);
            setTipoFormulario({ nombre: '', descripcion: '', visible: false });
        } catch (error) {
            console.error('Error al guardar tipo de carnet:', error);
            setError('No se pudo guardar el tipo de carnet.');
        }
    };

    // 3️⃣ Cargar datos en el formulario cuando se edita
    const handleEditClick = (tipo) => {
        setTipoEnEdicion(tipo.id);
        setTipoFormulario({ nombre: tipo.nombre, descripcion: tipo.descripcion, visible: tipo.visible });
    };

    // 4️⃣ Cancelar edición y limpiar formulario
    const handleCancelEdit = () => {
        setTipoEnEdicion(null);
        setTipoFormulario({ nombre: '', descripcion: '', visible: false });
        setError('');
    };

    return (
        <div className="p-6 w-full">
            <h2 className="text-2xl font-semibold text-center mb-4">
                Tipos de Carnet {paisId ? ` - País: ${paisId}` : ''}
            </h2>
            {error && <p className="text-red-500 mb-2">{error}</p>}

            {/* 🔹 Formulario para Añadir o Editar un Tipo de Carnet */}
            <div className="p-4 border rounded-md shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-2">
                    {tipoEnEdicion ? 'Editar Tipo de Carnet' : 'Añadir Nuevo Tipo de Carnet'}
                </h3>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Nombre del Carnet:</label>
                    <input
                        type="text"
                        value={tipoFormulario.nombre}
                        onChange={(e) => setTipoFormulario({ ...tipoFormulario, nombre: e.target.value })}
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: Tipo B"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Descripción:</label>
                    <textarea
                        value={tipoFormulario.descripcion}
                        onChange={(e) => setTipoFormulario({ ...tipoFormulario, descripcion: e.target.value })}
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Describe brevemente el tipo de carnet..."
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Visible:</label>
                    <input
                        type="checkbox"
                        checked={tipoFormulario.visible}
                        onChange={(e) => setTipoFormulario({ ...tipoFormulario, visible: e.target.checked })}
                        className="mt-1"
                    />
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={handleSubmit}
                        className={`px-4 py-2 text-white rounded-md ${tipoEnEdicion ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'}`}
                    >
                        {tipoEnEdicion ? 'Guardar Edición' : 'Añadir Tipo de Carnet'}
                    </button>
                    {tipoEnEdicion && (
                        <button
                            onClick={handleCancelEdit}
                            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                        >
                            Cancelar
                        </button>
                    )}
                </div>
            </div>

            {/* 🔹 Lista de Tipos de Carnet */}
            <div className="overflow-x-auto">
                {loading ? (
                    <p className="text-center text-gray-500">Cargando tipos de carnet...</p>
                ) : tipos.length === 0 ? (
                    <p className="text-center text-gray-500">No se encontraron tipos de carnet.</p>
                ) : (
                    <table className="w-full bg-white border border-gray-200 shadow-md rounded-md">
                        <thead>
                            <tr className="bg-gray-100 border-b">
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Nombre</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Descripción</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Visible</th>
                                <th className="py-2 px-4 text-center w-32">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tipos.map((tipo) => (
                                <tr key={tipo.id} className="border-b hover:bg-gray-50">
                                    <td className="py-2 px-4">{tipo.nombre}</td>
                                    <td className="py-2 px-4">{tipo.descripcion}</td>
                                    <td className="py-2 px-4">{tipo.visible ? 'Sí': 'No'}</td>
                                    <td className="py-2 px-4 text-center">
                                        <button
                                            onClick={() => handleEditClick(tipo)}
                                            className="bg-yellow-500 text-white px-3 py-1 rounded-md hover:bg-yellow-600 mr-2"
                                        >
                                            Editar
                                        </button>
                                        <Link
                                            to={`/paises/${paisId}/tipos-de-permisos/${tipo.id}/temario`}
                                            className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600"
                                        >
                                            Temario
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default TiposDeCarnet;