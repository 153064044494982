import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiClient from './apiClient';

const PaisesTable = () => {
    const [paises, setPaises] = useState([]);
    const [paisEnEdicion, setPaisEnEdicion] = useState(null);
    const [paisFormulario, setPaisFormulario] = useState({ nombre: '', codigo: '' });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const baseApiUrl = `${process.env.REACT_APP_API_BASE_URL}/paises`;

    // 1️⃣ Cargar países al montar el componente
    useEffect(() => {
        const fetchPaises = async () => {
            setLoading(true);
            try {
                const response = await apiClient(baseApiUrl);
                setPaises(response.data);
            } catch (error) {
                console.error('Error al cargar los países:', error);
                setError('No se pudieron cargar los países.');
            } finally {
                setLoading(false);
            }
        };
        fetchPaises();
    }, []);

    // 2️⃣ Añadir o Editar un país
    const handleSubmit = async () => {
        if (!paisFormulario.nombre || !paisFormulario.codigo) {
            setError('Todos los campos son obligatorios.');
            return;
        }
        setError('');

        try {
            if (paisEnEdicion) {
                // Modo edición (actualizar país existente)
                await apiClient(`${baseApiUrl}/${paisEnEdicion}`, {
                    method: 'PUT',
                    data: paisFormulario,
                });

                // Actualizar la lista local
                setPaises(
                    paises.map((p) => (p.id === paisEnEdicion ? { id: paisEnEdicion, ...paisFormulario } : p))
                );
            } else {
                // Modo añadir (crear nuevo país)
                const response = await apiClient(baseApiUrl, {
                    method: 'POST',
                    data: paisFormulario,
                });

                setPaises([...paises, response.data]);
            }

            // Resetear el formulario
            setPaisEnEdicion(null);
            setPaisFormulario({ nombre: '', codigo: '' });
        } catch (error) {
            console.error('Error al guardar el país:', error);
            setError('No se pudo guardar el país.');
        }
    };

    // 3️⃣ Cargar datos en el formulario cuando se edita
    const handleEditClick = (pais) => {
        setPaisEnEdicion(pais.id);
        setPaisFormulario({ nombre: pais.nombre, codigo: pais.codigo });
    };

    // 4️⃣ Cancelar edición y limpiar formulario
    const handleCancelEdit = () => {
        setPaisEnEdicion(null);
        setPaisFormulario({ nombre: '', codigo: '' });
        setError('');
    };

    return (
        <div className="p-6 w-full">
            <h2 className="text-2xl font-semibold text-center mb-4">Gestión de Países</h2>
            {error && <p className="text-red-500 mb-2">{error}</p>}

            {/* 🔹 Formulario para Añadir o Editar un País */}
            <div className="p-4 border rounded-md shadow-md mb-6">
                <h3 className="text-xl font-semibold mb-2">
                    {paisEnEdicion ? 'Editar País' : 'Añadir Nuevo País'}
                </h3>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Nombre del País:</label>
                    <input
                        type="text"
                        value={paisFormulario.nombre}
                        onChange={(e) => setPaisFormulario({ ...paisFormulario, nombre: e.target.value })}
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: España"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium">Código del País:</label>
                    <input
                        type="text"
                        value={paisFormulario.codigo}
                        onChange={(e) => setPaisFormulario({ ...paisFormulario, codigo: e.target.value })}
                        className="w-full border border-gray-300 rounded-md p-2 mt-1"
                        placeholder="Ejemplo: ES"
                    />
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={handleSubmit}
                        className={`px-4 py-2 text-white rounded-md ${paisEnEdicion ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'}`}
                    >
                        {paisEnEdicion ? 'Guardar Edición' : 'Añadir País'}
                    </button>
                    {paisEnEdicion && (
                        <button
                            onClick={handleCancelEdit}
                            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                        >
                            Cancelar
                        </button>
                    )}
                </div>
            </div>

            {/* 🔹 Lista de Países */}
            <div className="overflow-x-auto">
                {loading ? (
                    <p className="text-center text-gray-500">Cargando países...</p>
                ) : paises.length === 0 ? (
                    <p className="text-center text-gray-500">No se encontraron países.</p>
                ) : (
                    <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-md">
                        <thead>
                            <tr className="bg-gray-100 border-b">
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Nombre del País</th>
                                <th className="py-2 px-4 text-left font-semibold text-gray-700">Código</th>
                                <th className="py-2 px-4 text-center font-semibold text-gray-700">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paises.map((pais) => (
                                <tr key={pais.id} className="border-b hover:bg-gray-50">
                                    <td className="py-2 px-4">{pais.nombre}</td>
                                    <td className="py-2 px-4">{pais.codigo}</td>
                                    <td className="py-2 px-4 text-center">
                                        <button
                                            onClick={() => handleEditClick(pais)}
                                            className="bg-yellow-500 text-white px-3 py-1 rounded-md hover:bg-yellow-600 mr-2"
                                        >
                                            Editar
                                        </button>
                                       
                                        <Link
                                            to={`/paises/${pais.id}/tipos-de-permisos`}
                                            className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600 mr-2"
                                        >
                                            Permisos
                                        </Link>
                                        <Link
                                            to={`/preguntas-reportadas`}
                                            className="bg-pink-500 text-white px-3 py-1 rounded-md hover:bg-pink-600"
                                        >
                                            Preguntas reportadas
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default PaisesTable;