import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseConfig";
import LogoutButton from "./LogoutButton"; // Botón de Logout
import UserTable from "./UserTable";
import Paises from "./Paises";
import Temario from "./Temas";
import TestsTable from "./TestsTable";
import MetricsTable from "./MetricsTable";
import QuestionForm from "./questions/QuestionsForm";
import QuestionsTable from "./questions/QuestionsTable";
import EditQuestion from "./questions/EditQuestion";
import UploadQuestions from "./questions/UploadQuestions";
import Login from "./Login";
import TiposDeCarnetTable from "./TiposDeCarnet";
import Preguntas from "./Preguntas";
import SeccionesTable from "./SeccionesTable";
import PreguntasReportadas from "./PreguntasReportadas";

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <div>Loading...</div>;
    }
    return user ? children : <Navigate to="/login" />;
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-100 p-6">
        <header className="flex justify-between items-center mb-6">
          <Link to="/paises" className="text-3xl font-bold text-blue-600 hover:underline">
            CarnetFacil.es
          </Link>          {user && <LogoutButton />} {/* Botón de Logout para usuarios autenticados */}
        </header>

        {/* Rutas */}
        <div className="bg-white p-6 rounded-md shadow-md">
          <Routes>
            <Route path="/login" element={user ? <Navigate to="/paises" /> : <Login />} />
            <Route
              path="/paises"
              element={
                <ProtectedRoute>
                  <Paises />
                </ProtectedRoute>
              }
            />
            <Route path="/paises/:paisId/tipos-de-permisos"
              element={
                <ProtectedRoute>
                  <TiposDeCarnetTable />
                </ProtectedRoute>
              } />

            <Route path="/paises/:paisId/tipos-de-permisos/:tipoId/temario"
              element={
                <ProtectedRoute>
                  <Temario />
                </ProtectedRoute>
              } />

            <Route path="/paises/:paisId/tipos-de-permisos/:tipoId/temario/:temaId/preguntas"
              element={
                <ProtectedRoute>
                  <Preguntas />
                </ProtectedRoute>
              } />
            <Route path="/paises/:paisId/tipos-de-permisos/:tipoId/temario/:temaId/secciones"
              element={
                <ProtectedRoute>
                  <SeccionesTable />
                </ProtectedRoute>
              } />
            <Route
              path="/questions/import"
              element={
                <ProtectedRoute>
                  <UploadQuestions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/preguntas-reportadas"
              element={
                <ProtectedRoute>
                  <PreguntasReportadas />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
